body {
  --primary: #000;
  --secondary: #fff;
  --neutral: #fff;
  --onPrimary: #fff;
  --onSecondaryHigh: #000;
  --onSecondaryLow: #494d58;
  --onNeutral: #494d58;
  --danger: #ff3939;
  --dangerSurface: #ffcaca;
  --selected: #000; /* Radio Checkbox selected */

  --content-padding: 1.25rem 1.25rem;

  --inputRestingBorder: 2px solid transparent; /* Need this transparent line to better animate the focus-visible state */
  --inputFocusBorder: 2px solid var(--primary);

  --copyFontFamilyHigh: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  --copyFontFamilyLow: Arial, sans-serif;
  --boldWeight: 600;
  --regularWeight: 400;
  --allcaps-letter-spacing: .07em;
  --field-border-radius: 8px;
  --footer-height: 112px;

  margin: 0;
  font-family: var(--copyFontFamilyLow);
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media (min-width:320px) { 
  body {
    --h1Size: 1.5rem;
    --h2Size: 1.25rem;
  }
}

@media (min-width:961px) {
  body {
    --h1Size: 2rem;
    --h2Size: 1.25rem
  }
}

h1,
h2,
h3,
h4,
h5,
h6, 
.as-h1 {
  font-family: var(--copyFontFamilyHigh);
  text-align: center;
}

h1, .as-h1 {
  font-size: var(--h1Size);
  font-weight: var(--boldWeight);
}

h2, .as-h2 {
  font-size: var(--h2Size);
  font-weight: var(--boldWeight);
}

p {
  font-size: 1rem;
}

body * { box-sizing: border-box }

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

header {
  padding: 1rem;
  width: 100%;
  position: relative;
}
  
header a { text-decoration: none }

header .react-aria-Button {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 1rem;
  top: 50%;
  padding: 0;
  transform: translateY(-50%);
  background-color: transparent;
  box-shadow: none;
  font-size: .875rem;
}

header .react-aria-Button > * {
  margin-right: .5rem;
}

header .react-aria-Button > *:last-child {
  margin-right: 0;
}

#content {
  height: 100%;
  width: 100%;
  max-width: 500px;
  padding: var(--content-padding);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  flex: 1;
}

#content > * {
  margin-bottom: 1.5rem;
}

#content > *:last-child {
  margin-bottom: 0;
}

.neutral-bg {
  background-color: var(--neutral);
}

.neutral-bg h1, .neutral-bg .as-h1 { color: var(--onNeutral) }
.neutral-bg footer, .neutral-bg p { color: var(--onNeutral) }
.neutral-bg header { 
  box-shadow: 0 4px 20px #00000012;
  button { color: var(--secondary) }
  button svg path { fill: var(--secondary) }
}

.secondary-bg {
  background-color: var(--secondary);
}
.secondary-bg h1, .secondary-bg .as-h1 { color: var(--onSecondaryHigh) }
.secondary-bg h2, .secondary-bg p, .secondary-bg footer { color: var(--onSecondaryLow) }
.secondary-bg header { 
  box-shadow: 0 4px 20px #0000002e;
}

.secondary-bg header button {
  color: var(--onSecondaryHigh)
}

.secondary-bg header button svg path {
  fill: var(--onSecondaryHigh)
}

footer {
  height: var(--footer-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

footer div {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  font-size: .75rem;
}

footer div * {
  margin-right: 1em;
}

footer div *:last-child {
  margin-right: 0;
}

footer .version-number {
  display: block;
  text-align: center;
  font-size: .625rem;
  opacity: .5;
  margin-top: 1rem;
}

.primary-button {
  background-color: var(--primary);
  color: var(--onPrimary);
  border: none;
}

.secondary-button {
  background-color: var(--secondary);
  color: var(--onSecondaryHigh);
  border: 1px solid var(--onSecondaryHigh);
}

.react-aria-Link, a:not(.react-aria-Button):any-link {
  transition: all 100ms ease;
  color: inherit;
}

.react-aria-Link:hover a:not(.react-aria-Button):any-link:hover { color: var(--primary)!important }
.react-aria-Link:visited a:not(.react-aria-Button):any-link:visited { color: inherit }
 
.react-aria-Label {
  font-size: .875rem;
  color: var(--primary);
  text-transform: none;
  font-family: var(--copyFontFamilyLow);
  font-weight: var(--boldWeight);
  display: block;
  margin-bottom: .5rem;
  text-align: left;
}

.react-aria-FieldError {
    display: block;
    color: var(--danger);
    font-size: .875rem;
    margin-top: .5rem;
    text-align: left;
}

.react-aria-Button {
  font-family: var(--copyFontFamilyHigh);
  font-size: 1rem;
  padding: 1em 1.5em;
  border-radius: var(--field-border-radius);
  box-shadow: 4px 4px 20px #0000004d;
  transition: all 300ms;
  cursor: pointer;
  font-weight: var(--boldWeight);
  text-transform: none;

  /* For Link Buttons */
  text-align: center;
  text-decoration: none;
}

.react-aria-Button[data-disabled] {
  cursor: not-allowed;
  filter: saturate(60%) brightness(115%);
  box-shadow: none;
}

.react-aria-Button[data-focused] {
  outline: none;
}

.react-aria-Button[data-pressed] {
  box-shadow: 0px 0px 4px #0000003a;
  filter: brightness(90%);
}

.data-list-container {
  position: relative;
  width: 100%;
}

.data-list-container div {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  pointer-events: none;
}

.react-aria-ComboBox,
.react-aria-Select {
  width: 100%;
}

input, .react-aria-SelectValue {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: var(--field-border-radius);;
  border: none;
  background-color: var(--neutral);
  padding: 1rem;
  font-size: 1rem;
  transition: all 300ms;
}

input {
  border: var(--inputRestingBorder);
}

input:focus-visible {
  outline: none;
  border: var(--inputFocusBorder);
}

input:disabled {
  opacity: 1;
}

button {
  outline: none;
  cursor: pointer;
  border: none;
  box-sizing: border-box;
}

.react-aria-Popover[data-trigger=ComboBox],
.react-aria-Popover[data-trigger=Select] {
  min-width: var(--trigger-width);
  background-color: var(--neutral);
  border-radius: var(--field-border-radius);;
  border: 1px solid var(--onNeutral);
  box-shadow: 0 0 4px #00000050;
  overflow: hidden;
}

.react-aria-Popover[data-trigger=ComboBox] .react-aria-ListBox,
.react-aria-Popover[data-trigger=Select] .react-aria-ListBox {
  display: block;
  min-width: unset;
  max-height: inherit;
  min-height: unset;
  border: none;
  overflow: auto;
}

.react-aria-Popover[data-trigger=ComboBox] .react-aria-ListBox .react-aria-Header,
.react-aria-Popover[data-trigger=Select] .react-aria-ListBox .react-aria-Header {
  padding-left: 1.5rem;
}

.react-aria-Popover[data-trigger=ComboBox] .react-aria-ListBoxItem,
.react-aria-Popover[data-trigger=Select] .react-aria-ListBoxItem {
  padding: 1em 2em;
  text-align: left;
  border-bottom: 1px solid #494d5827;
}

.react-aria-Popover[data-trigger=ComboBox] .react-aria-ListBoxItem[data-focus-visible],
.react-aria-Popover[data-trigger=Select] .react-aria-ListBoxItem[data-focus-visible] {
  outline: none;
}

.react-aria-Popover[data-trigger=ComboBox] .react-aria-ListBoxItem[data-selected],
.react-aria-Popover[data-trigger=Select] .react-aria-ListBoxItem[data-selected] {
  font-weight: var(--regularWeight);
  background: unset;
  color: var(--onNeutral);
  position: relative;
}

.react-aria-Popover[data-trigger=ComboBox] .react-aria-ListBoxItem[data-selected]::before,
.react-aria-Popover[data-trigger=Select] .react-aria-ListBoxItem[data-selected]::before {
  content: '✓';
  content: '✓' / '';
  alt: ' ';
  position: absolute;
  top: 50%;
  left: .875em;
  transform: translateY(-50%);
}

.react-aria-Popover[data-trigger=ComboBox] .react-aria-ListBoxItem[data-focused],
.react-aria-Popover[data-trigger=ComboBox] .react-aria-ListBoxItem[data-pressed],
.react-aria-Popover[data-trigger=Select] .react-aria-ListBoxItem[data-focused]
.react-aria-Popover[data-trigger=Select] .react-aria-ListBoxItem[data-pressed] {
  background: var(--primary);
  color: var(--onPrimary);
}

.react-aria-Select .react-aria-SelectValue {
  padding: 1rem;
  text-align: left;
}

.react-aria-Select button {
  width: 100%;
  background-color: var(--neutral);
  outline: none;
  border: var(--inputRestingBorder);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  transition: all 300ms;
  font-family: var(--copyFontFamilyLow);
  font-weight: var(--regularWeight);
  letter-spacing: .01em;
  text-transform: initial;
}

.react-aria-Select button[data-pressed="true"] {
  border: var(--inputFocusBorder);
}

.react-aria-Select button span[data-placeholder="true"] {
  opacity: .5;
}

.react-aria-Select button span:nth-child(2) {
  margin-top: 9px;
}

.react-aria-ProgressBar {
  top: 50%;
  left: 50%;
  z-index: 200;
  position: fixed;
  margin-top: -32px;
  margin-left: -32px;
}

.react-aria-ProgressBar:not([aria-valuenow]) .fill {
  width: 120px;
  border-radius: inherit;
  animation: indeterminate 1.5s infinite ease-out;
  animation-direction: reverse;
  will-change: transform;
  transform-origin: center;
}

@keyframes indeterminate {
  to {
    transform: rotate(-360deg);
  }
}

form, fieldset {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  border: none;
  margin: 0 auto;
  padding: 0;
}

form > *, fieldset > * {
  margin-bottom: 2rem;
}

form > *:last-child, fieldset > *:last-child {
  margin-bottom: 0;
}

form .react-aria-Button, fieldset .react-aria-Button{
  width: 100%;
}

.react-aria-RadioGroup {
  display: flex;
  flex-direction: column;
  color: var(--onSecondaryLow);
  width: 100%;
  margin: 1rem 0;
}

.react-aria-RadioGroup > * {
  margin-bottom: 1rem;
}

.react-aria-RadioGroup > *:last-child {
  margin-bottom: 0;
}

.react-aria-Radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: var(--onSecondaryLow);
  background-color: var(--secondary);
  text-align: left;
  min-height: 70px;
  border: 1px solid var(--onNeutral);
  padding: 0 1em;
  border-radius: var(--field-border-radius);
  transition: all 300ms;
  box-shadow: 4px 4px 20px #00000000;
}

.react-aria-Radio > * {
  margin-right: .75rem;
}

.react-aria-Radio > *:last-child {
  margin-right: 0;
}

.react-aria-Radio:before {
  content: '';
  display: block;
  width: 1.75rem;
  height: 1.75rem;
  box-sizing: border-box;
  border: .1rem solid;
  border-color: var(--onSecondaryHigh);
  background: transparent;
  border-radius: 50%;
  transition: all 300ms;
  margin-right: .75em;
}

.react-aria-Radio[data-pressed]:before {
  border-color: var(--border-color-pressed);
}

.react-aria-Radio[data-selected] {
  border-color: var(--primary);
  box-shadow: 4px 4px 20px #0000004d;

  &:before {
    border-color: var(--onSecondaryHigh);
    border-width: .5rem;
  }

  &[data-pressed]:before {
    border-color: var(--onSecondaryHigh);
  }
}

.react-aria-Radio[data-selected]:before {
  border-color: var(--onSecondaryHigh);
  border-width: .5rem;
}


.react-aria-Radio[data-selected][data-pressed]:before {
  border-color: var(--onSecondaryHigh);
}

.react-aria-Radio[data-focus-visible], .react-aria-Radio[data-focus-visible]:before {
  outline: 2px solid var(--onSecondaryHigh);
  outline-offset: 2px;
}

.react-aria-Radio .react-aria-Button {
  background: transparent;
  padding: 0;
  margin: 0;
  box-shadow: none;
  flex: 0;
}

.react-aria-Radio svg path {
  fill: var(--onSecondaryLow);
}

.react-aria-Radio div {
  flex: 1;
}

.react-aria-Popover {
  --background-color: var(--onNeutral);
  border: 1px solid var(--onNeutral);
  box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
  border-radius: 6px;
  background: var(--background-color);
  color: var(--text-color);
  outline: none;
  max-width: 250px;
}

.react-aria-Popover .react-aria-OverlayArrow svg {
  display: block;
  fill: var(--onNeutral);
  stroke: var(--onNeutral);
  stroke-width: 1px;
}

.react-aria-Popover[data-placement=top] {
  --origin: translateY(8px);
}

.react-aria-Popover[data-placement=top]:has(.react-aria-OverlayArrow) {
  margin-bottom: 6px;
}

.react-aria-Popover[data-placement=bottom] {
  --origin: translateY(-8px);
}

.react-aria-Popover[data-placement=bottom]:has(.react-aria-OverlayArrow) {
  margin-top: 6px;
}

.react-aria-Popover[data-placement=bottom] .react-aria-OverlayArrow svg {
  transform: rotate(180deg);
}

.react-aria-Popover[data-placement=right] {
  --origin: translateX(-8px);
}

.react-aria-Popover[data-placement=right]:has(.react-aria-OverlayArrow) {
  margin-left: 6px;
}

.react-aria-Popover[data-placement=right] .react-aria-OverlayArrow svg {
  transform: rotate(90deg);
}

.react-aria-Popover[data-placement=left] {
  --origin: translateX(8px);
}

.react-aria-Popover[data-placement=left]:has(.react-aria-OverlayArrow) {
  margin-right: 6px;
}

.react-aria-Popover[data-placement=left] .react-aria-OverlayArrow svg {
  transform: rotate(-90deg);
}

.react-aria-Popover[data-entering] {
  animation: popover-slide 200ms;
}

.react-aria-Popover[data-exiting] {
  animation: popover-slide 200ms reverse ease-in;
}

.react-aria-Popover .react-aria-Dialog {
  padding: 1rem;
  color: var(--onSecondaryLow);
}

.react-aria-Popover .react-aria-Dialog:focus {
  outline: none;
}

@keyframes popover-slide {
  from {
    transform: var(--origin);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.info-icon path {
  fill: var(--onSecondaryLow);
}

.fancy-license-plate {
  padding: 12px 16px;
  width: 100%;
  background-color: var(--primary);
  color: var(--onPrimary);
  font-weight: 700;
  letter-spacing: .09em;
  border-radius: 8px;
  border: 1px solid #ffffff85;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20), 0px 2px 6px rgba(0, 0, 0, 0.15);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.20);
  justify-content: center;
}